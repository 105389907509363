.b-relation-actualites {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  header {
    .o-layout {
      display: grid;
      grid-template-columns: 1fr calc(#{rem(40px)} + #{rem(40px)} + 1rem);
      grid-gap: rem($unit);
      align-items: flex-end;
    }
  }
  .b-relation-actualites_deco {
    position: absolute;
    top: rem(-$unit * 2);
    left: 0;
    width: 100%;
  }
  .b-relation-actualites_slide {
    height: 100%;
  }
  .b-relation_slide {
    height: 100%;
    display: flex;
  }
}
