.b-intro-slider-fullsize {
  .b-intro-slider-fullsize_slide {
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 91px);
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $black-overlay-light;
    }
    .o-container {
      width: 100%;
      max-width: 1440px!important;
      position: relative;
      z-index: 2;
    }
    .b-intro-slider-fullsize_slide_wrap {
      .b-intro-slider-fullsize_slide_content {

      }
    }
  }
  position: relative;
  .c-slider_container {
    position: relative;
  }
  .b-intro-slider-fullsize_slider-pagination {
    position: absolute;
    //top: 0;
    left: 50%!important;
    max-width: 1440px;
    transform: translateX(-50%);
    //padding: rem($unit-large) 0;
    //bottom: inherit!important;
    bottom: calc(#{rem($unit)} * 2)!important;
    padding: 0 rem($unit);
  }
  .b-intro-slider-fullsize_deco-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    transform: translateY(-50%);
  }
  .b-intro-slider-fullsize_deco-bottom {
    position: absolute;
    bottom: rem(-100px);
    left: 0;
    width: 100%;
    z-index: 3;
    //transform: translateY(50%);
  }
}
