.b-form {
  position: relative;
  z-index: 4;
  .b-form_wrap {
    box-shadow: 0 5px 20px $black-overlay-light;
    border-radius: 4px;
    transform: translateY(rem(-$unit));
    background-color: $white;
  }
}
