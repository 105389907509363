.c-slider_container {
  &.-offset-small {
    margin-right: rem(-$unit-small);
  }
  &.-offset {
    margin-right: rem(-$unit);
  }

  &.-offset-double {
    margin-right: calc(#{rem(-$unit)} * 2);
  }
}

.swiper-slide {
  height: auto!important;
}

.swiper-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  .swiper-navigation_button {
    width: rem(40px);
    height: rem(40px);
    border-radius: 4px;
    background-color: $grey-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
    overflow: hidden;
    svg {
      width: 14px;
    }
    position: relative;
    .icon {
      position: relative;
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      top: rem(80px);
      left: rem(-80px);
      width: rem(120px);
      height: rem(120px);
      transition: 0.6s ease-in-out;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: $grey-hover;
    }
    &:not(.swiper-button-disabled):hover {
      &::after {
        left: 50%;
        top: 50%;
      }
    }
    &.swiper-button-disabled {
      background-color: $grey-light;
      cursor: inherit;
    }
    &.-prev {
      svg {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }
}

.swiper-pagination {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  .swiper-pagination-bullet {
    margin-right: 1.5rem!important;
    width: rem(15px);
    height: rem(15px);
    background-color: transparent;
    border: rem(1.5px) solid $white;
    opacity: 1;
    cursor: pointer;
  }
  &.-black {
    .swiper-pagination-bullet {
      border-color: $black;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: $red;
    border-color: $red!important;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(30px);
      height: rem(30px);
      transform: translate(-50%, -50%);
      z-index: -1;
      background: transparent radial-gradient(closest-side at 50% 50%, $red 0%, $dots-overlay 90%) 0% 0% no-repeat padding-box;
      border-radius: 50%;
    }
  }
}
