.c-text {
  li {
    position: relative;
    padding-left: rem($unit-tiny);
    &::before {
      content: "";
      background-color: $second;
      border-radius: 50%;
      width: rem(5px);
      height: rem(5px);
      display: inline-block;
      position: absolute;
      top: rem(8px);
      left: 0;
    }
  }
}

blockquote {
  background-color: $second-extra-light;
  padding: rem($unit-small);
  padding-left: calc(#{rem(180px)} + #{rem($unit-small)});
  border-radius: rem(4px);
  display: grid;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    background-image: url('../images/temoignage-stroke.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: -1.5rem;
    width: rem(180px);
    height: rem(153px);
  }
}
