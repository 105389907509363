.b-devenir {
  position: relative;
  z-index: 2;
  .b-devenir_temoignage {
    display: flex;
    overflow: hidden;
    .b-devenir_temoignage_icon {
      transform: translateX(-1.5rem);
    }
    @media (max-width: $to-small) {
      flex-direction: column;
      .b-devenir_temoignage_icon {
        svg {
          max-width: rem(100px);
        }
      }
    }
  }
}
.b-devenir_actions_wrap {
  position: relative;
  z-index: 3;
}
.b-devenir_actions {
  &.-negative {
    transform: translateY(50%);
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 3;
  }
}
.check {
  display: grid;
  grid-template-columns: rem(32px) 1fr;
  grid-gap: rem($unit-tiny);
  margin-bottom: 0.5rem;
  &:last-of-type {
    margin-bottom: 0;
  }
}

