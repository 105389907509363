.c-nav {
  position: fixed;
  top: rem($header-height);
  left: 0;
  right: 0;
  z-index: 7;
  //visibility: hidden;
  transform: translateY(-100%);
  transition: transform 0.3s $easing;
  height: calc(100vh - #{rem($header-height)});
  overflow: hidden;
  /*.c-nav_bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $primary;
      z-index: -1;
    }
  }*/
  .c-nav_wrap {
    overflow-x: auto;
    height: calc(100vh - #{rem($header-height)});
    background-color: $primary;
    color: $white;
    padding: rem($unit) rem($unit-large);
    position: relative;
    &::before {
      content: "";
      background-color: $grey-hover;
      opacity: 0.3;
      width: 100%;
      height: 2px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .c-nav_primary {
      display: grid;
      grid-gap: rem($unit-small);
      transform: translateX(-50%);
      opacity: 0;
      transition: transform 1s $easing $speed, opacity 1.2s $easing 0.5s;
      a {
        color: $white;
        font-size: rem($font-size-h3);
        font-family: $font-roboto-condensed;
      }
    }
    @media (max-width: $to-big) {
      padding: rem($unit);
    }

  }
  .c-nav_deco {
    transform: translate(-50%, 50%);
    opacity: 0;
    transition: transform 1s $easing $speed, opacity 1.2s $easing 0.5s;
    svg {
      width: rem($deco-width);
    }
  }
  .c-nav_deco2 {
    position: absolute;
    bottom: -7rem;
    left: 50%;
    opacity: 0.1;
    z-index: 2;
    transform: translate(-50%, 50%);
    opacity: 0;
    transition: transform 1s $easing $speed, opacity 1.2s $easing 0.5s;
    svg {
      width: rem($deco-width);
    }
  }
  .c-nav_actions {
    height: 100%;
    transform: translateX(50%);
    opacity: 0;
    transition: transform 1s $easing $speed, opacity 1.2s $easing 0.5s;
    .c-nav_actions_buttons {
      display: flex;
      flex-direction: column;
    }
    .c-button {
      text-align: left;
    }
    .c-socials {
      margin-top: auto;
    }
  }
  .c-heading {
    &.-border {
      padding-bottom: 1rem;
      border-bottom: 1px solid $white;
    }
  }
  .c-nav_primary2_wrap {
    transform: translateX(-50%);
    opacity: 0;
    transition: transform 1s $easing $speed, opacity 1.2s $easing 0.5s;
  }
  .c-nav_primary2 {
    a {
      color: $white;
      opacity: 0.6;
      margin-bottom: 1rem;
      display: block;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .c-button {
    padding: 1.5rem;
    max-width: rem(290px);
  }
  .c-socials {
    a {
      color: $white;
      &::before {
        background-color: $second;
      }
    }
  }
  .anim-link {
    transition: $speed $easing;
    display: block;
    &:hover {
      transform: translateX(1rem);
      color: inherit;
    }
  }
}


.has-navOpen {
  .c-nav {
    transform: translateY(0);
    .c-nav_deco2 {
      transform: translate(0, 0);
      opacity: 0.1;
    }
    .c-nav_wrap {
      .c-nav_deco {
        transform: translate(0, 0);
        opacity: 1;
      }
      .c-nav_primary, .c-nav_primary2_wrap, .c-nav_actions {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}
