.b-liens {
  position: relative;
  .b-liens_wrap {
    display: grid;
    grid-gap: rem($unit-tiny);
  }
  .b-liens_deco {
    position: absolute;
    pointer-events: none;
    bottom: -2.5rem;
    left: 0;
    width: 100%;
    svg {
      width: rem(150px);
      transform: translateX(-50%);
    }
  }
}
.c-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0.5rem 0;
  color: $white;
  font-family: $font-roboto-condensed;
  text-transform: uppercase;
  svg {
    width: rem(12px);
  }
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 3px;
    background-color: $white;
    transition: $speed $easing;
    transform: translateY(-50%);
  }
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $white;
  }
  &:hover {
    color: $white;
    &::before {
      width: 100%;
    }
  }
  &.is-active {
    opacity: 0.5;
  }
}
