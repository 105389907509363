.c-socials {
  display: flex;
  svg {
    max-width: rem(15px);
  }
  a {
    margin-right: 1.5rem;
    width: rem(40px);
    height: rem(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brown;
    border-radius: 50%;
    position: relative;
    &:last-of-type {
      margin-right: 0;
    }
    &:before {
      content: "";
      background-color: $white;
      border-radius: 50%;
      width: rem(40px);
      height: rem(40px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      transition: $speed $easing;
    }
    &:hover {
      &:before {
        width: rem(50px);
        height: rem(50px);
      }
    }
  }
  &.-share {
    a {
      color: $white;
      &::before {
        background-color: $second;
      }
    }
  }
}
