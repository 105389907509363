.c-modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: $speed $easing;
  .c-modal_bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $black-overlay-light;
  }
  .c-modal_deco {
    position: absolute;
    right: -2rem;
    bottom: -2rem;
    z-index: 2;
  }
  .c-modal_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem($unit);
    position: relative;
    height: 100%;
    pointer-events: none;
    .c-modal_wrap {
      max-width: rem(700px);
      width: 100%;
      background-color: $primary;
      color: $white;
      padding: rem($unit);
      border-radius: 10px;
      position: relative;
      pointer-events: auto;
      background-size: cover;
      background-position: center;
      &.-filtre {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $black-overlay-light;
        }
      }
      .c-modal_content {
        position: relative;
        z-index: 2;
      }
      .c-modal_close {
        position: absolute;
        top: rem($unit-tiny);
        right: rem($unit-tiny);
        width: rem(40px);
        height: rem(40px);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        border-radius: 50%;
        z-index: 2;
        &:before {
          content: "";
          background-color: $white;
          border-radius: 50%;
          width: rem(40px);
          height: rem(40px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: $speed $easing;
        }
        svg {
          position: relative;
        }
        &:hover {
          &:before {
            width: rem(50px);
            height: rem(50px);
          }
        }
      }
    }
  }
}

.has-modalOpen {
  .c-modal {
    visibility: visible;
    opacity: 1;
  }
}
