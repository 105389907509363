// ==========================================================================
// Base / Fonts
// ==========================================================================

// @include font-face(
//     $font-foobar,
//     "fonts/Foobar/Regular"
// ) {
//     font-style:  normal;
//     font-weight: 400;
// }

// @include font-face(
//     $font-foobar,
//     "fonts/Foobar/Medium"
// ) {
//     font-style:  normal;
//     font-weight: 500;
// }

// @include font-face(
//     $font-foobar,
//     "fonts/Foobar/Semibold"
// ) {
//     font-style:  normal;
//     font-weight: 600;
// }

// @include font-face(
//     $font-bazqux,
//     "fonts/Bazqux/Regular",
//     ("eot", "woff2", "woff", "ttf")
// ) {
//     font-style:  normal;
//     font-weight: 400;
// }

@include font-face(
    $roboto,
    "fonts/Roboto/Roboto-Light",
    ("woff2", "woff")
) {
    font-style:  normal;
    font-weight: 300;
}

@include font-face(
    $roboto,
    "fonts/Roboto/Roboto-Medium",
    ("woff2", "woff")
) {
    font-style:  normal;
    font-weight: 500;
}

@include font-face(
    $roboto,
    "fonts/Roboto/Roboto-Regular",
    ("woff2", "woff")
) {
    font-style:  normal;
    font-weight: 400;
}

@include font-face(
    $roboto,
    "fonts/Roboto/Roboto-Bold",
    ("woff2", "woff")
) {
    font-style:  normal;
    font-weight: 700;
}

@include font-face(
    $roboto_condensed,
    "fonts/Roboto_Condensed/RobotoCondensed-Regular",
    ("woff2", "woff")
) {
    font-style:  normal;
    font-weight: 400;
}
@include font-face(
    $roboto_condensed,
    "fonts/Roboto_Condensed/RobotoCondensed-Italic",
    ("woff2", "woff")
) {
    font-style:  normal;
    font-weight: 400;
    font-style: italic;
}
