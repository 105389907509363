.c-heading {
    line-height: $line-height-default;
    margin: 0;
    font-family: $font-roboto-condensed;

    &.-noMargin {
      margin-bottom: 0;
    }

    &.-h1 {
        font-size: rem($font-size-h1);
    }
    &.-h1-big {
        font-size: rem($font-size-h1-big);
    }

    &.-h2 {
        font-size: rem($font-size-h2);
    }

    &.-h3 {
        font-size: rem($font-size-h3);
    }

    &.-h4 {
        font-size: rem($font-size-h4);
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        text-transform: uppercase;
        line-height: normal;
        &.-border {
          position: relative;
          padding-bottom: 1rem;
          display: inline-block;
          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 140%;
            height: 1px;
            background-color: $second-border;
          }
        }
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        text-transform: uppercase;
        &.-italic {
          font-style: italic;
          letter-spacing: 0.6px;
        }
    }

    @media (max-width: $to-small) {
        &.-h1 {
            font-size: rem($font-size-h1 - 15px);
        }
        &.-h1-big {
            font-size: rem($font-size-h1-big - 40px);
        }
        &.-h2 {
            font-size: rem($font-size-h2 - 10px);
        }
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        &.-h1-big {
            font-size: rem($font-size-h1-big - 10px);
        }
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        &.-h1-big {
            font-size: rem($font-size-h1-big - 5px);
        }
    }

    @media (min-width: $from-large) {
        &.-h1-big {
            font-size: rem($font-size-h1-big); /* [1] */
        }
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {

    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {

    }

    @media (min-width: $from-colossal) {

    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
