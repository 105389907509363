.b-intro-slider {
    position: relative;
    min-height: calc(100vh - #{rem($header-height)});
    max-width: 100vw;
    overflow: hidden;
    .o-container {
        width: 100%;
        position: relative;
        z-index: 2;
    }
    .b-intro-slider_image {
        position: absolute;
        right: 0;
        top: 0;
        width: 40%;
        height: 100%;
    }
    .b-intro-slider_deco-top {
        position: absolute;
        top: rem(-97px);
        left: 0;
        width: 100%;
        z-index: 3;
    }
    .b-intro-slider_deco-bottom {
        position: absolute;
        bottom: rem(-97px);
        left: 0;
        width: 100%;
        z-index: 3;
    }
    .h100 {
        height: 100%;
    }
    @media (max-width: $to-medium) {
        .b-intro-slider_image {
          position: relative;
          width: 100%;
        }
      }
}