.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-white {
  color: $white;
}
.u-c-grey {
  color: $grey;
}
.u-c-green {
  color: $green;
}
.u-c-red {
  color: $red;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-white {
  background-color: $white;
}
.u-bg-second-extra-light {
  background-color: $second-extra-light;
}
