// ==========================================================================
// Form
// ==========================================================================
.c-form {

}

.c-form_item {
    position: relative;
    margin-bottom: rem(30px);
    &.-noMargin {
      margin-bottom: 0;
    }

    ::placeholder {
      opacity: 0;
      transition: all 300ms ease;
    }
}

// Label
// ==========================================================================
.c-form_label {
    position: absolute;
    display: block;
    top: 1rem;
    left: 1.5rem;
    transition: 0.3s $easing;
    pointer-events: none;
    color: $second;
    font-family: $font-roboto-condensed;
    text-transform: uppercase;
    font-size: rem(12px);
}

.c-form_item {
  &.is-active {
    label {
      transform: translateY(calc(-100% - 1.2rem));
    }

    ::placeholder {
      opacity: 0.54;
    }
  }
  &.-select {
    .c-form_label {
      opacity: 1;
      text-transform: uppercase;
      font-weight: $medium;
    }
  }

  &:not(.is-active) {
      .c-form_input[type="date"] {
        color: transparent;
      }
  }
}
// Input
// ==========================================================================
$input-icon-color: 424242; // No #

.c-form_input {
    padding: 1rem 3rem;
    //border: 1px solid lightgray;
    background-color: $second-extra-light;
    border-radius: 4px;

    /*&:hover {
        border-color: darkgray;
    }

    &:focus {
        border-color: dimgray;
    }*/

    /*&::placeholder {
        color: gray;
    }*/
}

// Checkbox
// ==========================================================================
$checkbox: rem(18px);
$checkbox-icon-color: $input-icon-color;

.c-form_checkboxLabel {
    @extend .c-form_label;

    position: relative;
    display: inline-block;
    margin-right: rem(10px);
    margin-bottom: 0;
    padding-left: ($checkbox + rem(10px));
    cursor: pointer;

    &::before, &::after {
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        margin-top: (-$checkbox / 2);
        padding: 0;
        width: $checkbox;
        height: $checkbox;
        content: "";
    }

    &::before {
        background-color: $white;
        border: 1px solid lightgray;
    }

    &::after {
        border-color: transparent;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2210.5%22%20viewBox%3D%220%200%2013%2010.5%22%20enable-background%3D%22new%200%200%2013%2010.5%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23#{$checkbox-icon-color}%22%20d%3D%22M4.8%205.8L2.4%203.3%200%205.7l4.8%204.8L13%202.4c0%200-2.4-2.4-2.4-2.4L4.8%205.8z%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(12px);
        background-repeat: no-repeat;
        opacity: 0;
    }

    &:hover {
        &::before {
            border-color: darkgray;
        }
    }

    .c-form_checkbox:focus + & {
        &::before {
            border-color: dimgray;
        }
    }

    .c-form_checkbox:checked + & {
        &::after {
            opacity: 1;
        }
    }
}

.c-form_checkbox {
    position: absolute;
    width: 0;
    opacity: 0;
}

// Radio
// ==========================================================================
$radio-icon-color: $input-icon-color;

.c-form_radioLabel {
    @extend .c-form_checkboxLabel;

    &::before, &::after {
        border-radius: 50%;
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20enable-background%3D%22new%200%200%2013%2013%22%20xml%3Aspace%3D%22preserve%22%3E%3Ccircle%20fill%3D%22%23#{$radio-icon-color}%22%20cx%3D%226.5%22%20cy%3D%226.5%22%20r%3D%226.5%22%2F%3E%3C%2Fsvg%3E");
        background-size: rem(6px);
    }
}

.c-form_radio {
    @extend .c-form_checkbox;
}

// Select
// =============================================================================
$select-icon: rem(40px);
$select-icon-color: $input-icon-color;

.c-form_select {
    position: relative;
    cursor: pointer;

    /*&::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: $select-icon;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23#{$select-icon-color}%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(8px);
        background-repeat: no-repeat;
        content: "";
        pointer-events: none;
    }*/

    * {
      color: $black;
    }

    &.-button {
      height: 100%;
      min-width: rem(250px);
      padding: rem(15px) rem(20px);
    }
}
.c-form_select-grey {
  background-color: $grey-dark;
  color: $white;
  border-radius:rem(5px);
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    right: rem($unit-tiny);
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.c-form_select-second {
  background-color: $second-extra-light;
  color: $grey;
  border-radius:rem(5px);
  position: relative;
  select {
    text-transform: uppercase;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: rem($unit-tiny);
    transform: translateY(-50%);
    pointer-events: none;
    color: $primary;
  }
}

.c-form_select_input {
    @extend .c-form_input;

    position: relative;
    z-index: 1;
    padding-right: $select-icon;
    cursor: pointer;
}

// Textarea
// =============================================================================
.c-form_textarea {
    @extend .c-form_input;

    min-height: rem(200px);
}

.error {
  color: $red;
}
.valid {
  color: $green;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  cursor: pointer;
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted $black;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label {
  display: flex;
  width: 100%;
  padding: 0;
  outline: none;
  align-items: center;
}

.inputfile + label .title {
  font-weight: $normal;
  display: flex;
  flex-direction: column;
  margin-left: rem($unit-tiny);
}

.inputfile + label .c-button {
  width: rem(48px);
  height: rem(48px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $normal;
  font-size: 1.5rem;
}

[type="radio"]:not(:checked), [type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="radio"]:not(:checked)+span, [type="radio"]:checked+span {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;
  user-select: none;
}

[type="radio"]+span:before, [type="radio"]+span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease;
}

[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after, [type="radio"]:checked+span:before, [type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
  border-radius: 50%;
}

[type="radio"]:checked+span:before {
  border: 2px solid transparent;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
  background-color: $second;
}

[type="radio"]:checked+span:after {
  transform: scale(1.02);
}

[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
  border: 2px solid $black;
}

[type="radio"]:not(:checked)+span:after {
  transform: scale(0);
}

.c-form-checkbox {
  label {
    color: $second;
  }
  [type="checkbox"]:not(:checked), [type="checkbox"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  [type="checkbox"]+span:not(.lever) {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  [type="checkbox"]+span:not(.lever):before, [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 1px solid $primary;
    border-radius: 4px;
    margin-top: 3px;
    -webkit-transition: .2s;
    transition: .2s;
  }
  [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    border: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked+span:not(.lever):before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid $second;
    border-bottom: 2px solid $second;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    border-radius: 1px;
  }
}

[data-form="remove"] {
  display: none;
}

.c-tooltip {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  display: grid;
  grid-template-columns: 1fr rem(25px);
  grid-gap: 1rem;
  max-width: calc(100% - 2rem);
  cursor: help;
  .c-tooltip_icon {
    width: rem(25px);
    height: rem(25px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    color: $white;
    grid-column: 2;
    svg {
      width: 5px;
    }
  }
  .c-tooltip_overlay {
    display: none;
    color: $white;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: rem(12px);
    transform: translateY(-0.3rem);
    &:before {
      content: '';
      position: absolute;
      top: 12px;
      right: -8px;
      width: 0;
      height: 0;
      border-top : 4px solid transparent;
      border-bottom : 4px solid transparent;
      border-left : 8px solid rgba(0, 0, 0, 0.8);
    }
  }
  &:hover {
    .c-tooltip_overlay {
      display: block;
    }
  }
}