.c-footer {
  background-color: $brown;
  hr {
    border-color: $brown-border;
    height: 2px;
    border-width: 2px;
  }
  a.c-footer_link, span, p, address {
    color: $white;
    opacity: 0.5;
  }
  a.c-footer_link {
    margin-right: 1.5rem;
    font-weight: $medium;
    .icon {
      margin-left: 1rem;
      svg {
        width: 14px;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      opacity: 1;
    }
  }
  .c-footer_contact {
    a {
      margin: 0;
    }
    .separator {
      display: inline-block;
      margin: 0 1.5rem;
    }
  }
  .c-footer_bottom {
    .c-footer_bottom_links {
      text-align: right;
      @media (max-width: $to-small) {
        text-align: left;
      }
    }
  }
  .c-socials {
    justify-content: flex-end;
    @media (max-width: $to-small) {
      justify-content: flex-start;
    }
  }
}
