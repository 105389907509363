.c-flap-call {
  position: fixed;
  bottom: 0;
  right: rem($unit);
  z-index: 5;
  transform: translateY(calc(100% - #{rem(46px)}));
  transition: $speed $easing;
  .c-button {
    width: 100%;
  }
  .c-button.-green {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .c-flap-call_wrap {
    background-color: $white;
    padding: 1.5rem;
    box-shadow: 0 5px 15px $black-overlay-light;
  }
  @media (max-width: $to-tiny) {
    right: 50%;
    transform: translateY(calc(100% - #{rem(46px)})) translateX(50%);
    width: 100%;
    width: calc(100% - #{rem(40px)});
  }
}
.c-flap-call_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  visibility: hidden;
}

.has-flapOpen {
  .c-flap-call {
    transform: translateY(0);
    @media (max-width: $to-tiny) {
      transform: translateY(0) translateX(50%);
    }
  }
  .c-flap-call_bg {
    visibility: visible;
  }
}
