.c-flap-sttraten {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: rem($unit);
  background-color: $white;
  border-radius: 5px;
  z-index: 5;
  padding: rem($unit-tiny);
  box-shadow: 0 5px 15px $black-overlay-light;
  width: calc(#{rem(48px)} + (#{rem($unit-tiny)} * 2));
  transition: $speed $easing;
  overflow: hidden;
  .icon {
    width: rem(48px);
    height: rem(48px);
    background-color: $second-extra-light;
    color: $primary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: rem(20px);
    }
    &.-red {
      color: $white;
      background-color: $red;
      font-size: rem(26px);
    }
  }
  .item {
    display: grid;
    grid-template-columns: rem(48px) 1fr;
    grid-gap: rem($unit-tiny);
    align-items: center;
    margin-bottom: rem($unit-tiny);
    &:last-of-type {
      margin-bottom: 0;
    }
    &.-border {
      padding-bottom: rem($unit-tiny);
      border-bottom: 2px solid $second-extra-light;
      margin-bottom: rem($unit-tiny);
    }
    .label {
      white-space: nowrap;
      opacity: 0;
      transition: $speed $easing;
    }
  }
  &:hover, &.is-active {
    width: rem(200px);
    .item {
      .label {
        opacity: 1;
      }
    }
  }
  @media (max-width: $to-big) {
    left: 0;
  }
  @media (max-width: $to-small) {
    display: none;
  }
}

.c-flap-sttraten-mobile {
  display: none;
  position: absolute;
  top: rem($header-height);
  //left: 50%;
  //transform: translateX(-50%);
  background-color: $white;
  border-radius: 5px;
  z-index: 5;
  padding: rem($unit-tiny);
  box-shadow: 0 5px 15px $black-overlay-light;
  min-width: rem(300px);
  width: calc(100% - #{rem(40px)});
  margin: 0 rem(20px);
  .c-flap-sttraten-mobile_dropdown {
    .c-flap-sttraten-mobile_dropdown_header {
      display: grid;
      grid-template-columns: 1fr 25px;
      align-items: center;
      grid-gap: rem($unit);
      line-height: 1;
    }
    .c-flap-sttraten-mobile_dropdown_body {
      opacity: 0;
      transition: $speed $easing;
      max-height: 0;
    }
    &:hover {
      .c-flap-sttraten-mobile_dropdown_body {
        opacity: 1;
        max-height: 200px;
        padding-top: rem($unit-tiny);
      }
    }
  }
  .icon {
    width: rem(48px);
    height: rem(48px);
    background-color: $second-extra-light;
    color: $primary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: rem(20px);
    }
    &.-red {
      color: $white;
      background-color: $red;
      font-size: rem(26px);
    }
  }
  .item {
    display: grid;
    grid-template-columns: rem(48px) 1fr;
    grid-gap: rem($unit-tiny);
    align-items: center;
    margin-bottom: rem($unit-tiny);
    &:last-of-type {
      margin-bottom: 0;
    }
    &.-border {
      padding-bottom: rem($unit-tiny);
      border-bottom: 2px solid $second-extra-light;
      margin-bottom: rem($unit-tiny);
    }
  }
  @media (max-width: $to-small) {
    display: block;
  }
}
