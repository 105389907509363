.b-tiroirs {
  position: relative;
  .b-tiroirs_deco {
    position: absolute;
    bottom: rem(-97px);
    left: 0;
    width: 100%;
    //transform: translateY(50%);
  }
}
