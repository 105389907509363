.b-page-header {
  position: relative;
  z-index: 3;
  .h100 {
    height: 100%;
  }
  .b-page-header_deco {
    opacity: 0.1;
    position: absolute;
    left: 50%;
    top: rem(-100px);
    width: rem(150px);
    pointer-events: none;
  }
  .b-page-header_deco2 {
    position: absolute;
    width: 100%;
    transform: translateY(-4rem);
    pointer-events: none;
    .b-page-header_deco2_wrap {
      display: flex;
      justify-content: flex-end;
    }
    svg {
      width: rem(150px);
    }
  }
  .b-page-header_actions {
    &.-negative {
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
  .o-layout_item {
    position: relative;
  }
  .b-page-header_deco-red {
    position: absolute;
    width: 100%;
    bottom: rem($unit);
    left: 0;
    svg {
      transform: translateX(50%);
    }
    &.-negative {
      bottom: rem(-97px);
      z-index: 1;
    }
  }
  .b-page-header_secteur {
    display: grid;
    grid-template-columns: rem(124px) 1fr;
    grid-gap: rem($unit-small);
    align-items: center;
    @media (max-width: $to-small) {
      grid-template-columns: rem(65px) 1fr;
      align-items: flex-start;
    }
  }
  .b-page-header_icon {
    width: rem(124px);
    height: rem(124px);
    color: $white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(250, 250, 250, 0.14);
    border-radius: 50%;
    svg {
      width: rem(45px);
    }
    @media (max-width: $to-small) {
      width: rem(62px);
      height: rem(62px);
      svg {
        width: rem(22px);
      }
    }
  }
  .b-page-header_image {
    transform: translateY(6rem);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 33.3%;
    height: calc(100% - #{rem($unit)});
    .o-ratio {
      &::before {
        padding-bottom: 80%;
      }
    }
    @media (max-width: $to-small) {
      position: relative;
      width: 80%;
      text-align: right;
      transform: translateY(rem(-$unit-small));
      margin-bottom: calc(#{rem(-$unit)} * 2);
      margin-left: auto;
    }
  }

  .b-page-header_image-legende {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    color: $white;
    padding: 0.5rem;
  }

  .b-page-header_image_hover {
    .b-page-header_image1 {
      transition: $speed $easing;
    }
    .b-page-header_image2 {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    &:hover {
      .b-page-header_image1 {
        opacity: 0;
      }
      .b-page-header_image2 {

      }
    }
  }
}
