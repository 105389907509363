.b-single {
  .b-single_item {
    margin: rem($unit) 0;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:first-of-type {
      margin-top: 0;
    }
  }
  hr {
    &:last-of-type {
      display: none;
    }
  }
  .b-single_side {
    .b-single_side_wrap {
      background-color: $second-extra-light;
      padding: 0 rem($unit-small);
    }
    .b-single_side_row {
      padding: rem($unit-small) 0;
      border-bottom: 1px solid $grey;
      font-weight: $light;
      display: flex;
      align-items: center;
      &:last-of-type {
        border: none;
      }
      .icon {
        margin-right: rem($unit-tiny);
      }
    }
    .c-button {
      margin-top: rem($unit-tiny);
      width: 100%;
    }
  }

}

.b-single_presentation {
  position: relative;
  z-index: 4;
  .b-single_presentation_content {
    position: relative;
    &::after {
      content: "";
      background-color: $second;
      height: 1px;
      width: rem(100px);
      position: absolute;
      left: rem($unit-large);
      bottom: 0;
    }
  }
  .b-single_presentation_image {
    transform: translateY(rem(-$unit));
    position: absolute;
    top: 0;
    right: 0;
    width: 33.3%;
    height: calc(100% + #{rem($unit)});
    .o-ratio {
      &::before {
        padding-bottom: 65%;
      }
    }
    @media (max-width: $to-small) {
      position: relative;
      width: 80%;
      text-align: right;
      transform: translateY(rem(-$unit-small));
      margin-bottom: calc(#{rem(-$unit)} * 2);
      margin-left: auto;
    }
  }
  &.-page {
    .b-single_presentation_content {
      &::after {
        display: none;
      }
    }
    .b-single_presentation_image {
      transform: translateY(calc(#{rem(-$unit)} * 2));
      height: calc(100% + calc(#{rem($unit)} * 2));
      .o-ratio {
        &::before {
          padding-bottom: 90%;
        }
      }
      @media (max-width: $to-small) {
        position: relative;
        width: 80%;
        text-align: right;
        transform: translateY(rem(-$unit-small));
        margin-bottom: calc(#{rem(-$unit)} * 2);
        margin-left: auto;
      }
    }
    .b-single_presentation_deco {
      position: absolute;
      top: calc( (#{rem(-$unit)} * 2) - #{rem(97px)} );
      left: 70%;
      z-index: 2;
    }
  }
}
