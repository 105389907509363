.b-cta {
  position: relative;
  overflow: hidden;
  .b-cta_deco-top {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
    width: 100%;
    transform: translateY(-80px);
    svg {
      width: rem(150px);
    }
  }
  .b-cta_deco {
    position: absolute;
    bottom: rem(-60px);
    left: 0;
    width: 100%;
  }
}
