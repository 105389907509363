.b-candidature-dashboard {
  .b-candidature-dashboard_item {
    overflow: hidden;
    border-radius: rem(10px);
    a {
      padding: rem($unit);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $second-extra-light;
    }
  }
}
