.b-intro-slider-images {
  position: relative;
  min-height: calc(100vh - 91px);
  max-width: 100vw;
  overflow: hidden;
  .o-container {
    width: 100%;
    //max-width: rem(1280px)!important;
    position: relative;
    z-index: 2;
  }
  .b-intro-slider-images_media {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    .b-intro-slider-images_slide_deco-left {
      position: absolute;
      z-index: -1;
      left: 0;
      top: rem(100px);
      transition: 1.8s $easing 1.5s;
      transform: translateY(50%);
      opacity: 0;
    }
    .b-intro-slider-images_slide_deco-right {
      position: absolute;
      z-index: -1;
      left: rem(320px);
      top: rem(197px);
      transition: 1.8s $easing 1.7s;
      transform: translateY(50%);
      opacity: 0;
    }
  }
  .b-intro-slider_slide {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    img {
      width: auto;
      max-width: none;
      max-height: 90%;
    }
  }
  .b-intro-slider-images_deco-top {
    position: absolute;
    top: rem(-97px);
    left: 0;
    width: 100%;
    z-index: 3;
  }
  .b-intro-slider-images_deco-bottom {
    position: absolute;
    bottom: rem(-97px);
    left: 0;
    width: 100%;
    z-index: 3;
  }
  .b-intro-slider-images_wrap {

  }
  .b-intro-slider-media_slide {
    clip-path: url(#video-clip-path);
    height: 100%;
    max-height: 100vh;
    iframe, video {
      height: 100%;
      width: 100%;
    }
  }
  .c-slider_container {
    overflow: hidden;
    height: 100%;
  }
  .c-embed {
    height: 100%;
  }

  .c-slider {
    position: relative;
  }
  @media (max-width: $to-medium) {
    .b-intro-slider-images_media {
      position: relative;
      width: 100%;
      .b-intro-slider_slide {
        img {
          max-width: 350px;
        }
      }
    }
  }
}
.svg-hide {
  width: 0;
  height: 0;
  position: absolute;
}

.is-ready.is-animated {
  .b-intro-slider-images {
    .b-intro-slider-images_media {
      .b-intro-slider-images_slide_deco-left {
        transform: translate(0, 0);
        opacity: 1;
      }
      .b-intro-slider-images_slide_deco-right {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
}
