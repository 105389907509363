.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  height: rem($header-height);
  border-bottom: 1px solid $grey-border;
  .c-header_nav {
    display: flex;
  }
  .logo {
    &.-white {
      display: none;
    }
  }

  a:not(.c-header_logo), button {
    margin-left: 1.5rem;
  }

  .c-header_nav_burger {
    display: flex;
    align-items: center;
    color: $primary;
    font-weight: $medium;
    .c-header_nav-icon {
      position: relative;
      width: 40px;
      height: 22px;
      cursor: pointer;
      margin-left: 1rem;
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $primary;
        border-radius: 2px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 10px;
          width: 75%;
        }
        &:nth-child(3) {
          top: 20px;
        }
      }
    }
    &.is-active {
      .c-header_nav-icon {
        span {
          &:nth-child(1) {
            top: 10px;
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
            left: -60px;
          }
          &:nth-child(3) {
            top: 10px;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }


  @media (min-width: $from-small) {
    padding: 1rem rem(40px);
  }
  @media (max-width: $to-small) {
    padding: 1rem rem(20px);
  }
}

.has-navOpen {
  .c-header {
    background-color: $primary;
    .logo {
      &.-white {
        display: block;
      }
      &.-color {
        display: none;
      }
    }
  }
  .c-header_nav_burger {
    color: $white;
    .c-header_nav-icon {
      span {
        background: $white;
      }
    }
  }
}
