.c-accordion_wrap {
  background-color: $white;
  border-radius: 4px;
}
.c-accordion_item {
  border-bottom: 1px solid $grey;
  padding: 1rem 0;
  /*&:last-of-type {
    border-bottom: none;
  }*/
  .c-accordion_header {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem($unit-small) 0;
    transition: color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    .c-heading {
      padding-right: 50px;
    }
  }
  .c-accordion_plus {
    position: relative;
    width: 1rem;
    height: 1rem;
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    flex-shrink: 0;
    margin-left: 0.66667rem;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      transition: border-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::before {
      border-top: 2px solid $primary;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -1px;
    }
    &::after {
      border-left: 2px solid $primary;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -1px;
    }
    &.-svg {
      &::after, &::before {
        display: none;
      }
    }
    .icon {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      svg {
        max-width: 50px;
      }
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.is-open {
    .c-accordion_main {
      height: auto;
      padding-bottom: 2rem;
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
    }
    .c-accordion_plus::after {
      transform: scale(0);
    }
    .u-c-primary {
      color: $second!important;
    }
    .c-accordion_plus {
      &::before {
        border-color: $second;
      }
    }
  }
}
