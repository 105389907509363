.b-relation-pages {
  header {
    .o-layout {
      display: grid;
      grid-template-columns: 1fr calc(#{rem(40px)} + #{rem(40px)} + 1rem);
      grid-gap: rem($unit);
      align-items: flex-end;
    }
  }
}
