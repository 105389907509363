.b-galerie {
  .b-galerie_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 350px);
    grid-gap: 2.5rem;
    .b-galerie_item {
      &:nth-child(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
      &:nth-child(2) {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
      }
      &:nth-child(3) {
        grid-column: 3 / 3;
        grid-row: 1 / 2;
      }
      &:nth-child(4) {
        grid-column: 1 / 2;
        grid-row: 2 / 4;
      }
      &:nth-child(5) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
      }
      &:nth-child(6) {
        grid-column: 2 / 4;
        grid-row: 3 / 4;
      }
    }
  }
  .c-figure {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
