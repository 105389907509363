.b-titre-texte {
  position: relative;
  z-index: 2;
  .b-titre-texte_deco {
    position: absolute;
    bottom: rem(-75px);
    left: 0;
    width: 100%;
    svg {
      width: rem(150px);
    }
  }
}
