.c-breadcrumb {
  position: absolute;
  top: 0;
  left: 0;
  padding: rem($unit-small) rem($unit-small) rem($unit-small) rem($unit-large);
  color: $white;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  z-index: 2;
  a {
    color: $white;
    text-decoration: underline;
  }
  .separator {
    margin: 0 rem($unit-tiny);
    width: rem(24px);
    height: rem(1px);
    background-color: $white;
  }
  @media (max-width: $to-small) {
    padding: rem($unit-small) rem($unit-small) rem($unit-small) rem($unit-small);
  }
}
