.c-button {
    padding: rem(15px) rem(20px);
    overflow: hidden;
    font-weight: $medium;
    position: relative;
    color: $white;
    border-radius: 4px;
    .c-button_label {
      display: inline-block;
      position: relative;
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      top: rem(-90px);
      left: rem(-500px);
      width: rem(500px);
      height: rem(500px);
      transition: 0.6s ease-in-out;
      transform: rotate(45deg);
    }
    .c-button_icon {
      display: inline-block;
      margin-right: 1rem;
      position: relative;
      z-index: 2;
      &.-right {
        margin-left: 1rem;
        margin-right: 0;
      }
      svg {
        width: 14px;
      }
    }

    &.-second {
      background-color: $second;
      &::after {
        background-color: $second-hover;
      }
    }

    &.-grey {
      background-color: $grey-dark;
      &::after {
        background-color: $grey-hover;
      }
    }

    &.-white {
      background-color: $white;
      color: $second;
      &::after {
        background-color: $second-light;
      }
    }

    &.-second-extra-light {
      background-color: $second-extra-light;
      color: $second;
      &::after {
        background-color: $second-light;
      }
    }

    &.-green {
      background-color: $green;
      &::after {
        background-color: $green-hover
      }
    }

    &.-red {
      background-color: $red;

      &::after {
        background-color: $red-hover
      }
    }

    &.-carre {
      width: rem(50px);
      height: rem(50px);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .c-button_icon-animate {
      width: rem(48px);
      margin-left: 1.5rem;
      display: inherit;
      svg {
        z-index: 2;
        position: absolute;
        bottom: -1rem;
        right: 1rem;
        width: rem(48px);
        transition: $speed $easing;
      }
    }

    &.-large {
      padding: rem(22px) rem(30px);
    }

    @include u-hocus {
      color: $white;
      &::after {
        //left: 0;
        left: rem(-150px);
      }
      &.-white {
        color: $second;
      }
      &.-second-extra-light {
        color: $second;
      }
      .c-button_icon-animate {
        svg {
          bottom: -0.5rem;
          right: 0.5rem;
        }
      }
    }
}

.c-button_dl {
  display: grid;
  grid-template-columns: rem(44px) 1fr;
  grid-gap: rem($unit-tiny);
  align-items: center;
  color: $white;
  .icon {
    width: rem(44px);
    height: rem(44px);
    color: $second;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    &:before {
      content: "";
      width: rem(44px);
      height: rem(44px);
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: $speed $easing;
    }
  }
  &:hover {
    color: $white;
    .icon {
      &::before {
        width: rem(54px);
        height: rem(54px);
      }
    }
  }
}
