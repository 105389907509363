.b-punchlines {
  header {
    position: relative;
  }
  .b-punchlines_deco-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
  }
  .b-punchlines_deco-left {
    position: absolute;
    top: 50%;
    left: 0;
    width: 150px;
  }
}
