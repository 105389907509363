// ==========================================================================
// Settings / Config
// ==========================================================================

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend  !default;

// Path is relative to the stylesheets directory.
$assets-path: "../"  !default;

// Typefaces
// =============================================================================
$font-sans-serif: sans-serif;
$roboto: 'Roboto';
$roboto_condensed: 'Roboto Condensed';
$font-roboto: $roboto, $font-sans-serif;
$font-roboto-condensed: $roboto_condensed, $font-sans-serif;

// Typography
// =============================================================================
// Base
$font-size:   14px;
$line-height: 21px / $font-size;
$line-height-default: 1;
$font-family: $font-roboto;
$color:       $black;
// Headings
$font-size-h1:  64px  !default;
$font-size-h2:  50px  !default;
$font-size-h3:  30px  !default;
$font-size-h4:  20px  !default;
$font-size-h5:  14px  !default;
$font-size-h6:  12px  !default;
$font-size-h1-big: 110px  !default;
//$line-height-h: $line-height;
$line-height-h: $line-height-default;
// Weights
$light:  300;
$normal: 400;
$medium: 500;
$bold:   700;

// Transitions
// =============================================================================
$speed:  0.3s;
$easing: $Power2EaseOut;

// Spacing Units
// =============================================================================
$unit:       56px;
$unit-small: 28px;
$unit-large: 168px;
$unit-tiny:  14px;

// Container
// ==========================================================================
$container-width: 1080px;
$container-large-width: 1280px;
$container-small-width: 768px;
//$padding:         $unit;
$padding:         80px;

// Breakpoints
// =============================================================================
$from-tiny:     500px              !default;
$to-tiny:       $from-tiny - 1     !default;
$from-small:    700px              !default;
$to-small:      $from-small - 1    !default;
$from-medium:   1000px             !default;
$to-medium:     $from-medium - 1   !default;
$from-large:    1200px             !default;
$to-large:      $from-large - 1    !default;
$from-big:      1400px             !default;
$to-big:        $from-big - 1      !default;
$from-huge:     1600px             !default;
$to-huge:       $from-huge - 1     !default;
$from-enormous: 1800px             !default;
$to-enormous:   $from-enormous - 1 !default;
$from-gigantic: 2000px             !default;
$to-gigantic:   $from-gigantic - 1 !default;
$from-colossal: 2400px             !default;
$to-colossal:   $from-colossal - 1 !default;

$header-height: 91px !default;
$deco-width: 150px !default;
