// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #3E4042;
$black-overlay-light: rgba(0, 0, 0, 0.29);

$primary: #0F3B60;

$second: #259AD5;
$second-hover: #116282;
$second-extra-light: #f4f9fc;
$second-border: #4089BB;
$second-light: #DCEFF7;

$red: #EF455F;
$red-hover: #c7354b;
$green: #23d9af;
$green-hover: #20ba97;

$grey: #afb0b1;
$grey-light: #e1e2e2;
$grey-dark: #3e4042;
$grey-hover: #606264;
$grey-border: #DEE5ED;

$brown: #2B2C2E;
$brown-border: #1A1B1C;

$shadow: #1842651A;
$dots-overlay: #EF455F00;

// Specific
// =============================================================================
// Link
$link-color:       $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $white;
$selection-background-color: $primary;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
