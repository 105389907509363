.b-relation_filtres {
  margin-bottom: rem(-$unit);
  z-index: 3;
  position: relative;
}
.b-relation_filtres_wrap {
  background-color: $white;
  padding: rem($unit-small);
  border-radius: rem(5px);
  transform: translateY(#{rem(-$unit)});
  .-filtres-btn {
    svg {
      width: rem(24px);
    }
    .c-button_label {
      color: $primary;
      text-transform: uppercase;
    }
  }
  .b-relation_filtres_head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .b-relation_filtres_resultats {
      margin-left: auto;
    }
  }
  .b-relation_filtres_actions {
    display: flex;
    flex-wrap: wrap;
  }
  .b-relation_filtres_foot {
    border-top: rem(1px) solid $grey;
    padding-top: rem($unit-small);
    margin-top: rem($unit-small);
    &.hide {
      display: none;
    }
  }
}

.b-relation_filtres_sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  transition: $speed $easing;
  transform: translateX(100%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  .c-accordion_item {
    .c-accordion_header {
      padding: rem($unit-tiny) 0;
    }
  }
  .b-relation_filtres_sidebar_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $black-overlay-light;
    z-index: -1;
  }
  .b-relation_filtres_sidebar_head {
    padding: rem($unit-tiny) rem($unit-small);
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .b-relation_filtres_sidebar_body {
    padding: rem($unit-small);
    background-color: $white;
    overflow: auto;
  }
  .b-relation_filtres_sidebar_foot {
    padding: rem($unit-tiny) rem($unit-small);
    background-color: $second-extra-light;
    border-top: 1px solid $grey;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem($unit-tiny);
    margin-top: auto;
    button {
      flex: 1;
    }
  }
  .b-realtion_filtres_sidebar_form {
    display: inline-flex;
    height: 100%;
    flex-direction: column;
      background-color: $white;
  }
  &.is-active {
    transform: translateX(0);
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination_total {
  padding: 0.5rem;
  background-color: $white;
  border-radius: 5px;
  font-weight: $bold;
}
