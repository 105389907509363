.b-equipe {
  .b-equipe_item {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    .b-equipe_item_images {
      position: relative;
      height: rem(230px);
      .b-equipe_item_photo1 {
        transition: $speed $easing;
      }
      .b-equipe_item_photo2 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
    .b-equipe_item_content {
      height: calc(100% - #{rem(230px)});
      display: flex;
      flex-direction: column;
      .c-socials {
        margin-top: 0.5rem;
        position: relative;
        z-index: 2;
        a {
          color: $white;
          margin-right: 1rem;
          &:before {
            background-color: $second;
          }
        }
      }
    }
    &:hover {
      .b-equipe_item_images {
        .b-equipe_item_photo1 {
          opacity: 0;
        }
        .b-equipe_item_photo2 {

        }
      }
    }
  }
}
