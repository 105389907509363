.c-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem($unit);
  svg {
    width: rem(300px);
  }
  transition: transform 1.5s $easing , visibility 0s 2s;
  /*.logo {
    transform: translateY(rem($unit));
    opacity: 0;
    transition: 1.5s $easing;
    color: $white;
  }*/
}

.is-animated.is-ready , .is-transitioned, .is-transitioning {
  .c-loader {
    transform: translateY(-100%);
    visibility: hidden;

    /*.logo {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }*/
  }
}
