.c-card {
  &.-actualite {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 5px 20px $shadow;
    height: 100%;
    .c-card_wrap {
      display: grid;
      grid-template-columns: rem(150px) 1fr;
      height: 100%;
      .c-card_image {
        overflow: hidden;
        img {
          transition: 0.3s $Power2EaseInOut;
        }
      }
      .c-card_content {
        background-color: $white;
        display: flex;
        flex-direction: column;
        .c-card_body {
          padding: $unit-small;
        }
        .c-card_foot {
          padding: $unit-tiny $unit-small;
          border-top: 1px solid $grey;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: auto;
          svg {
            width: 14px;
          }
        }
      }
    }
    &.-listing {
      .c-card_wrap {
        grid-template-columns: 1fr;
        grid-template-rows: rem(220px) 1fr;
        .c-card_image {
          height: rem(220px);
        }
      }
    }
    &:hover {
      .c-card_wrap {
        .c-card_image {
          img {
            transform: scale(1.05);
          }
        }
        .c-heading:not(.-h6), p, span {
          color: $second!important;
        }
        svg {
          path {
            fill: $second;
          }
        }
      }
    }
    @media (max-width: $to-small) {
      .c-card_wrap {
        grid-template-columns: 1fr;
        grid-template-rows: rem(200px) 1fr;
      }
    }
  }
  &.-page {
    height: 100%;
    .c-card_wrap {
      .c-card_image {
        overflow: hidden;
        height: 250px;
        img {
          transition: 0.3s $Power2EaseInOut;
        }
      }
      .c-card_content {
        .c-card_body {
          .c-card_title {
            display: grid;
            grid-template-columns: 1fr rem(50px);
            grid-gap: 1rem;
            justify-content: space-between;
            svg {
              width: 14px;
            }
          }
        }
      }
    }
    &:hover {
      .c-card_wrap {
        .c-card_image {
          img {
            transform: scale(1.05);
          }
        }
        .c-heading:not(.-h6), p, span {
          color: $second!important;
        }
      }
    }
  }

  &.-offre {
    background-color: $white;
    padding: rem($unit-small);
    border-radius: rem(10px);
    height: 100%;
    .c-card_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      .c-card_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .c-card_body {

      }
      .c-card_contact {
        display: flex;
        align-items: center;
        line-height: 1;
        margin: rem($unit-tiny) 0 ;
        .icon {
          margin-right: rem($unit-tiny);
        }
        &:first-of-type {
          margin-top: rem($unit-small);
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .c-card_foot {
        border-top: 1px solid $grey;
        padding-top: rem($unit-small);
        margin-top: auto;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &.-temoignage {
    background-color: $white;
    padding: rem($unit-small);
    border-radius: rem(4px);
    width: 100%;
    height: 100%;
    .c-card_wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      .c-card_foot {
        margin-top: auto;
      }
    }
  }
  &.-secteur {
    background-color: $white;
    border-radius: 4px;
    padding: rem($unit-small);
    box-shadow: 0px 5px 20px $shadow;
    height: 100%;
    .c-card_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      .c-card_icon {
        width: rem(64px);
        height: rem(64px);
        background-color: $second-extra-light;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $second;
      }
      .c-card_foot {
        border-top: 1px solid $grey;
        padding-top: rem($unit-small);
        margin-top: auto;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
