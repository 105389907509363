.u-anim {
  opacity: 0;
  transform: translateY($unit-small);
  transition: 1.8s $easing;
  &.-delay1 {
    transition-delay: .3s;
  }
  &.-delay2 {
    transition-delay: .4s;
  }
  &.-delay3 {
    transition-delay: .5s;
  }
  &.-delay4 {
    transition-delay: .7s;
  }
  &.-delay5 {
    transition-delay: .8s;
  }
  &.-delay6 {
    transition-delay: .9s;
  }
  &.-delay7 {
    transition-delay: 1s;
  }
}

.u-anim-image {
  position: relative;
  opacity: 0;
  &::after {
    content: "";
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transform-origin: right;
    transition: 1.8s $easing;
    transition-delay: .3s;
    width: 100%;
  }
}

.is-ready.is-animated {
  .u-anim {
    opacity: 1;
    transform: translateY(0);
    &.b-page-header_actions {
      transform: translateY(-50%);
    }
  }
  .u-anim-image {
    opacity: 1;
    &::after {
      width: 0;
    }
  }
}
